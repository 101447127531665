import React, {useState} from 'react';
import {useHistory} from '../util/react-util.js';
import PageHeader from "./PageHeader";
import '../css/main.css'

function isNotLinkPushEvent(event) {
    return (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
            || event.defaultPrevented || event.button !== 0);
}

function Link(props) {
    let history = useHistory();
    let currentPage = history.location.hash === props.to;
    return (
        <a className="nav-link" aria-current={currentPage ? 'page' : null}
                href={props.to}
                onClick={function(event) {
                    if (isNotLinkPushEvent(event)) return;
                    event.preventDefault();
                    history.push(props.to);
                }}>
            {props.children}
        </a>);
}

/**
 * Component for wrapping a page with a PageHeader, breadcrumb links and styling markup.
 */
export default function PageWrapper(props) {
    const {i18n, canvasHost, version} = props;
    return (
        <div className="App">
            <PageHeader i18n={i18n}/>
            <div className="layout-main">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <nav aria-label="Du är här" className="breadcrumbs">
                                    <ol className="breadcrumb">
                                        <li className="no-print breadcrumb-item"><a href={`${canvasHost}`}>Studium</a> </li>
                                        <li className="no-print breadcrumb-item"><a href={`${canvasHost}/accounts/1`}>UU</a> </li>
                                        <li className="no-print breadcrumb-item" aria-current="page"><span>{i18n('main.header.preamble')}</span></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 
                <div className="container-fluid jumbotron jumbotron-fluid mt-0">
                    <div className="d-flex justify-content-center">
                        <div className="jumbotron-text">
                            <h1>{i18n('main.header.preamble')}</h1>
                        </div>
                    </div>
                </div>
                */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 no-print">
                            <nav className="navbar-expand-lg" aria-label="Sidans meny">
                                <div className="collapse navbar-collapse">
                                    <ul className="nav flex-column">
                                        <li><Link to="#lti-tools-test" className="nav-link">LTI-verktyg</Link></li>
                                        <li><Link to="#sso-settings" className="nav-link">Inloggning</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <main className="col-lg-9">
                            { props.children }
                        </main>
                    </div>
                </div>
            </div>
            <footer className="layout-footer no-print">
                <div className="layout-footer-bg-img"></div>
                <div className="container">
                    <div className="row layout-footer-last-row">
                        <div className="col-12">
                            <article className="footer-site-information"
                                     aria-label="Kontakt- och organisationsinformation för Uppsala universitet">
                                <address>
                                    <span className="footer-site-entry">© Uppsala universitet</span>
                                    <span className="footer-site-entry">Telefon:&nbsp;
                                        <span className="tel">018-471 00 00</span>
                                    </span>
                                    <span className="footer-site-entry">Box 256, 751 05 Uppsala</span>
                                </address>
                                <p>
                                    <span className="footer-site-entry">Organisationsnummer:&nbsp;202100-2932</span>
                                    <span className="footer-site-entry">Momsregistreringsnummer:&nbsp;SE202100293201</span>
                                    <span className="footer-site-entry">PIC:&nbsp;999985029</span>
                                    <span className="footer-site-entry">
                                        <a href="http://www.uu.se/om-uu/kontakta-oss/registrator">Registrator</a>
                                    </span>
                                    <span className="footer-site-entry">
                                        <a href="http://www.uu.se/om-webbplatsen">Om webbplatsen</a>
                                    </span>
                                    <span className="footer-site-entry">
                                        <a href="https://www.uu.se/om-uu/dataskyddspolicy/">Dataskyddspolicy</a>
                                    </span>
                                </p>
                                {/*<p>
                                <span className="footer-site-entry">Sidansvarig:&nbsp;<a
                                    href="mailto:xxxx@uu.se">xxxx@uu.se</a></span>
                            </p>*/}
                            </article>
                            <p><span className="version text-muted">Version {version}</span></p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
