import React from "react";
import {useXhr} from '../util/react-util.js';

/**
 * LTI Tool configuration view for Studium test.
 */
export default function SsoSettingsView({i18n, betaSsoSettings, testSsoSettings, refresh, canvasTestHost, canvasBetaHost}) {
    const repo = useXhr();
    function updateSsoSettings() {
        repo.post('/staffsupport/updateSsoSettings', {}, function(r) {
            refresh();
        });
    }
    // let keys = Object.keys(betaSsoSettings);
    let prodLoginRe = /login\.studium\.uu\.se/; // "https://login.studium.uu.se/";
    let betaLogin = (betaSsoSettings||[]).auth_discovery_url;
    let betaLoginIsProd = (betaLogin||'').match(prodLoginRe);
    let testLogin = (testSsoSettings||[]).auth_discovery_url;
    let testLoginIsProd = (testLogin||'').match(prodLoginRe);
    return (
        <>
            <h2>{i18n('ssoSettings.header')}</h2>
            
            <p>{i18n('ssoSettings.description')}</p>
            
            <p>
                <a href={`${canvasTestHost}/accounts/1/authentication_providers`}>Studium test login</a>: {testLogin}
                {testLoginIsProd && <span style={{color: 'red'}}> &nbsp; Prod login!</span>}
                {/* !testLoginIsProd && <span style={{color: 'green'}}> &nbsp; OK!</span> */}
            </p>
            
            <p>
                <a href={`${canvasBetaHost}/accounts/1/authentication_providers`}>Studium beta login</a>: {betaLogin}
                {betaLoginIsProd && <span style={{color: 'red'}}> &nbsp; Prod login!</span>}
                {/* !betaLoginIsProd && <span style={{color: 'green'}}> &nbsp; OK!</span> */}
            </p>
            
            <div>
                {(testLoginIsProd || betaLoginIsProd)
                    ?   <button type="button"
                            onClick={updateSsoSettings} 
                            disabled={repo.waiting || !(testLoginIsProd || betaLoginIsProd)}>
                            {i18n('ssoSettings.update')}
                        </button>
                    :   <div className="alert alert-info">
                            {i18n('ssoSettings.noUpdateNeeded')}
                        </div>
                }
                {repo.waiting && <div>processing...</div>}
                {repo.error && <div className="alert alert-danger">ERROR! {repo.status}</div>}
            </div>
            
            {/* <pre>betaSsoSettings: {JSON.stringify(betaSsoSettings, null, 4)}</pre> */}
            {/* <pre>testSsoSettings: {JSON.stringify(testSsoSettings, null, 4)}</pre> */}
        </>
    );
}
