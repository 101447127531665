import React, {useEffect, useState} from 'react';
import reactUtil from './util/react-util.js';
import messages from './i18n/messages.js';
import {MessageError, MessageLoading} from "./components/Messages";
import PageWrapper from "./components/PageWrapper";
import LtiToolsView from "./components/LtiToolsView";
import SsoSettingsView from "./components/SsoSettingsView";

export default function App() {
    const i18n = reactUtil.useMessages(messages, "sv");
    const repo = reactUtil.useXhr();
    const [canvasHost, setCanvasHost] = useState();
    const [canvasTestHost, setCanvasTestHost] = useState();
    const [canvasBetaHost, setCanvasBetaHost] = useState();
    const [uuLtiTools, setUULtiTools] = useState();
    const [testLtiTools, setTestLtiTools] = useState();
    const [betaSsoSettings, setBetaSsoSettings] = useState();
    const [testSsoSettings, setTestSsoSettings] = useState();
    const [version, setVersion] = useState()
    const history = reactUtil.useHistory();
    const ltiToolView = history.location.hash === '#lti-tools-test';
    const ssoSettingsView = history.location.hash === '#sso-settings';
    if (!ltiToolView && !ssoSettingsView) history.push('#lti-tools-test');

    function init() {
        repo.get('/staffsupport/viewModel', {}, function(r) {
            i18n({messages: messages, locale: r.locale});
            setCanvasHost(r.canvasHost);
            setCanvasTestHost(r.canvasTestHost);
            setCanvasBetaHost(r.canvasBetaHost);
            setUULtiTools(r.uuLtiTools);
            setTestLtiTools(r.testLtiTools);
            setVersion(r.version);
            setBetaSsoSettings(r.betaSsoSettings);
            setTestSsoSettings(r.testSsoSettings);
            const preamble = `${i18n('main.header.preamble')}`;
            window.document.title = `${preamble}`;
        });
    }
    useEffect(init, []);
		    
    return (
        <>
            { repo.waiting && <MessageLoading>{i18n('common.loading')}</MessageLoading> }
            { repo.error && repo.status != 403 && <MessageError>{i18n('common.error')}</MessageError> }
            { repo.error && repo.status == 403 && <MessageError>{i18n('common.error.unauthorized')}</MessageError> }
            { repo.success &&
              <PageWrapper i18n={i18n} canvasHost={canvasHost} version={version} >
                  {ltiToolView && <LtiToolsView i18n={i18n} uuLtiTools={uuLtiTools} testLtiTools={testLtiTools} refresh={init} canvasTestHost={canvasTestHost} />}
                  {ssoSettingsView && <SsoSettingsView i18n={i18n} betaSsoSettings={betaSsoSettings} testSsoSettings={testSsoSettings} refresh={init} canvasTestHost={canvasTestHost} canvasBetaHost={canvasBetaHost} />}
              </PageWrapper>
            }
        </>
    );
}
