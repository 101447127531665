import React from "react";
import {useXhr} from '../util/react-util.js';

/**
 * LTI Tool configuration view for Studium test.
 */
export default function LtiToolsView({i18n, uuLtiTools, testLtiTools, refresh, canvasTestHost}) {
    const repo = useXhr();
    function updateLtiTools() {
        repo.post('/staffsupport/updateLtiTools', {}, function(r) {
            refresh();
        });
    }
    return (
        <>
            <h2>{i18n('ltiTools.header')} <a href={`${canvasTestHost}/accounts/1/settings/configurations#tab-tools`}>{i18n('ltiTools.studiumTest')}</a></h2>
            <p>{i18n('ltiTools.description')}</p>
            <ul>
                {(testLtiTools||[]).filter(tool => tool.isManaged || tool.isUuTool).map(tool =>
                <li key={tool.id}>
                    {tool.name}
                    {tool.hasProdConf && <span style={{color: 'red'}}>{i18n('ltiTools.hasProductionConfig')}</span>}
                    {/* !tool.hasProdConf && tool.isManaged && <span style={{color: 'green'}}>{i18n('ltiTools.isOk')}</span> */}
                    {/* !tool.isUuTool && <span style={{color: 'blue'}}>{i18n('ltiTools.isNonUUTool')}</span> */}
                    {tool.isUuTool && !tool.isManaged && <span style={{color: 'orange'}}>{i18n('ltiTools.isUnmanaged')}</span>}
                </li>
                )}
            </ul>
            <div>
                {(testLtiTools||[]).reduce((acc, t) => acc || t.hasProdConf, false)
                    ?   <button type="button"
                            onClick={updateLtiTools} 
                            disabled={repo.waiting || (testLtiTools||[])
                                .reduce((acc, t) => acc && !t.hasProdConf, true)}>
                            {i18n('ltiTools.update')}
                        </button>
                    :   <div className="alert alert-info">
                            {i18n('ltiTools.noUpdateNeeded')}
                            {(testLtiTools||[]).reduce((acc, t) => acc || (t.isUuTool && !t.isManaged), false) && 
                                <div style={{color: 'orange'}}>{i18n('ltiTools.unmanagedFound')}</div>
                            }
                        </div>
                }
                {repo.waiting && <div>processing...</div>}
                {repo.error && <div className="alert alert-danger">ERROR! {repo.status}</div>}
            </div>
            {/* <pre>testLtiTools: {JSON.stringify(testLtiTools, null, 4)}</pre> */}
            {/* <pre>uuLtiTools: {JSON.stringify(uuLtiTools, null, 4)}</pre> */}
            </>
    );
}
