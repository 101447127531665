/**
 * I18N messages.
 */
const messages = {
    'common.loading':{
        "sv": 'Laddar...',
        "en": 'Loading...'},
    'common.error': {
        "sv": 'Något gick tyvärr fel, ',
        "en": 'Something unexpected occured, '},
    'common.error.unauthorized': {
        "sv": 'Du är inte behörig att utföra åtgärden',
        "en": 'You are not authorized to perform this request'},
    'main.header.preamble': {
        "sv": 'Internsupport ',
        "en": 'Staff support '},
    'ltiTools.header': {
        "sv": 'UU LTI verktyg på ',
        "en": 'UU LTI Tools On '},
    'ltiTools.description': {
        "sv": 'LTI verktyg på Studium test. Konfigurationerna hos de hanterade verktygen (de som finns med i Internsupportverktygets register), uppdateras varje morgon kl. 05.00 ifall de blivit överskrivna med produktionsvärden.',
        "en": "LTI tools on Studium test. The configurations of the managed tools (those in the Staff support tool register), are updated each morning at 05:00 in case they've been overwritten with production values."},
    'ltiTools.studiumTest': {
        "sv": 'Studium test',
        "en": 'Studium Test'},
    'ltiTools.isOk': {
        "sv": ' ',
        "en": ' '},
    'ltiTools.hasProductionConfig': {
        "sv": ' har produktionskonfiguration',
        "en": ' has production config'},
    'ltiTools.isNonUUTool': {
        "sv": ' ej UU verktyg',
        "en": ' non UU tool'},
    'ltiTools.isUnmanaged': {
        "sv": ' ej hanterat',
        "en": ' unmanaged'},
    'ltiTools.noUpdateNeeded': {
        "sv": 'Inget av de hanterade LTI-verktygen behöver uppdateras på Studium test.',
        "en": 'None of the managed LTI tool needs to be updated on Studium test.'},
    'ltiTools.unmanagedFound': {
        "sv": 'Det finns UU LTI verktyg som eventuellt skulle behöva läggas till i listan över hanterade verktyg.',
        "en": 'There are some UU LTI tools which might need to be included in the list of managed tools.'},
    'ltiTools.update': {
        "sv": 'Uppdatera konfiguration',
        "en": 'Update configuration'},
    'ssoSettings.header': {
            "sv": 'Inloggning på Studium test och beta',
            "en": 'Login on Studium test and beta'},
    'ssoSettings.description': {
        "sv": 'Login konfiguration på Studium test och beta. Konfigurationerna uppdateras varje morgon kl. 05.00 ifall de blivit överskrivna med produktionsvärden.',
        "en": "Login configurations on Studium test and beta. The configurations are updated every morning at 05:00 in case they've been overwritten with production values."},
    'ssoSettings.update': {
            "sv": 'Uppdatera inloggning',
            "en": 'Update login'},
    'ssoSettings.noUpdateNeeded': {
            "sv": 'Inloggning behöver ej uppdateras.',
            "en": 'No login update needed.'}
        }
export default messages;
